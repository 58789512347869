import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { get } from "../../../../../app/modules/auth/core/helpers";
import { CreateTask } from "../../../../partials";

const TasksTab = (props) => {
  const { modalChange } = props;
  const [tasks, setTasks] = useState<any>([]);
  const history = useLocation();
  const [showModal, setShowModal] = useState<any>(false);
  const [incomingTask, setIncomingTask] = useState<any>(false);
  useEffect(() => {
    async function fetch() {
      const taskData = await get("api/get-tasks");
      console.log(taskData);
      setTasks(taskData.data);
    }
    fetch();
  }, [modalChange, showModal, history]);

  const hideModal = () => {
    setShowModal(false);
  };

  const show = (data) => {
    setShowModal(true);
    console.log(data);
    setIncomingTask(data);
  };

  return (
    <>
      <div className="mx-5">
        {/*begin::Header*/}
        <h3 className="fw-bolder text-dark mb-10 mx-0">Tasks Overview</h3>
        {/*end::Header*/}

        {/*begin::Body*/}
        <div className="mb-12">
          {tasks &&
            tasks.length &&
            tasks.map((t) => (
              <div className="d-flex align-items-center mb-7" key={t.title}>
                {/*begin::Symbol*/}

                {/*end::Symbol*/}

                {/*begin::Text*/}
                <div className="d-flex flex-column">
                  <div
                    role="button"
                    onClick={() => show(t)}
                    className="text-gray-800 text-hover-primary fs-6 fw-bold"
                  >
                    {t.title}
                  </div>

                  <span className="text-muted fw-bold">{t.description}</span>
                </div>
                {/*end::Text*/}
              </div>
            ))}
        </div>

        {/*end::Body*/}
      </div>
      {showModal && (
        <>
          <CreateTask handleClose={hideModal} incomingTask={incomingTask} />
          <div className="modal-backdrop fade show block"></div>
        </>
      )}
    </>
  );
};

export { TasksTab };
