import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export function get(url: string) {
  const token = localStorage.getItem("kt-auth-react-v");
  let headers;
  if (token) {
    headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token).token}`,
      },
    };
  }
  return axios.get(`${API_URL}/${url}`, headers);
}

export function post(url: string, data: any) {
  console.log(data);
  const token = localStorage.getItem("kt-auth-react-v");
  let headers = {};
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${JSON.parse(token).token}`,
    };
  }
  return axios.post(`${API_URL}/${url}`, { headers, data });
}

export function postImage(image: any) {
  console.log(image);
  const token = localStorage.getItem("kt-auth-react-v");

  let formData = new FormData(); // instantiate it
  // suppose you have your file ready
  formData.set("file", image);

  if (token) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(token).token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
  }
  return axios.post(`${API_URL}/api/save-image`, formData, {});
}

export function postImages(images: any) {
  console.log(images);
  const token = localStorage.getItem("kt-auth-react-v");

  let formData = new FormData(); // instantiate it
  // suppose you have your file ready
  Object.keys(images).forEach((key) => {
    formData.append("files[]", images[key]);
  });

  if (token) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(token).token;
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
  }
  return axios.post(`${API_URL}/api/save-images`, formData, {});
}

export function getClass(status) {
  let classVal = "";
  switch (status) {
    case "Pending":
      classVal = "badge-light-warning";
      break;
    case "Rejected":
      classVal = "badge-light-danger";
      break;
    case "Paid":
      classVal = "badge-light-success";
      break;
    case "Completed":
      classVal = "badge-light-success";
      break;
    case "Ready":
      classVal = "badge-light-info";
      break;
    case "Approved":
      classVal = "badge-light-primary";
      break;
  }
  return classVal;
}
