import { useEffect } from "react";
import { useIntl } from "react-intl";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import {
  ChargesTable,
  TablesWidget11,
} from "../../../_metronic/partials/widgets";

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/dashboard",
    isSeparator: false,
    isActive: false,
  },
];

const DashboardPage = () => {
  useEffect(() => {
    console.log("DashboardPage");
    // We have to show toolbar only for dashboard page
    document.getElementById("kt_layout_toolbar")?.classList.remove("d-none");
    return () => {
      document.getElementById("kt_layout_toolbar")?.classList.add("d-none");
    };
  }, []);

  return (
    <>
      <ChargesTable
        className="mb-5 mb-xl-8"
        Url={"api/get-charges-by-client"}
        title="Charges"
        titleSingular="Request"
        modalID=""
      />
      <TablesWidget11
        className="mb-5 mb-xl-8"
        Url={"api/get-all-notifications"}
        title="Requests"
        titleSingular="Request"
        modalID=""
        UpdateUrl={"api/update-notification"}
      />
    </>
  );
};

const DashboardWrapper = () => {
  const intl = useIntl();
  console.log("DashboardPage");
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
