/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon } from "../../../helpers";

import { StaffProfileDetails } from "../../../../app/modules/accounts/components/settings/cards/StaffProfileDetails";

type Props = {
  handleClose?: any;
  staff?: any;
};

const CreateStaff = ({ handleClose, staff }: Props) => {
  return (
    <div
      className="modal fade show block"
      id="kt_modal_invite_friends"
      aria-hidden="true"
    >
      <div className="modal-dialog mw-650px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              role="button"
              onClick={handleClose}
            >
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </div>

          <div className="modal-body mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center mb-13">
              <h1 className="mb-3">
                {staff ? "Update" : "Create"} A Staff Member
              </h1>
            </div>
            <StaffProfileDetails
              staff={staff}
              handleClose={handleClose}
              isModal
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { CreateStaff };
