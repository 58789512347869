import { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { KTIcon, toAbsoluteUrl } from "../../../../helpers";
import { getClients } from "../../../core/_requests";

const ClientsTab = (props) => {
  const { modalChange } = props;
  const [clients, dataClients] = useState<any>([]);
  const [originalClients, setOriginalClients] = useState<any>([]);
  const [q, setQ] = useState<string>("");
  const [activeClient, setActiveClient] = useState<any>(null);
  const { id } = useParams();
  const history = useLocation();

  useEffect(() => {
    setActiveClient(id);
    async function fetch() {
      const clientData = await getClients();
      const activeClientList = clientData.filter((item) => {
        return item.active === 1;
      });
      setOriginalClients(clientData);
      dataClients(activeClientList);
    }
    fetch();
  }, [modalChange, id, history]);

  const search = (e) => {
    console.log(e.target.value);
    setQ(e.target.value);
    if (e.target.value.length) {
      const clients = originalClients.filter((item) => {
        return (
          item.name
            .toString()
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) > -1
        );
      });
      dataClients(clients);
    } else {
      const activeClientList = originalClients.filter((item) => {
        return item.active === 1;
      });
      dataClients(activeClientList);
    }
  };

  return (
    <>
      <div className="m-0">
        {/* begin::Toolbar */}

        <div className="d-flex mb-10">
          <div className="w-100 position-relative mb-5 mb-lg-0">
            <KTIcon
              iconName="magnifier"
              className="fs-2 text-lg-3 text-gray-800 position-absolute top-50 translate-middle-y ms-5"
            />
            <input
              onChange={search}
              type="text"
              value={q}
              className="search-input form-control form-control-solid ps-13"
              name="search"
              placeholder="Search..."
              data-kt-search-element="input"
            />
          </div>
          {/* begin::Filter */}
        </div>

        {/*begin::Projects*/}
        <div className="m-0">
          {/*begin::Heading*/}
          <h1 className="text-gray-800 fw-bold mb-6 mx-5">Clients</h1>
          {/*end::Heading*/}

          {/*begin::Items*/}
          <div className="mb-10">
            {clients &&
              clients.length > 0 &&
              clients.map((p) => (
                <Link
                  key={p.customer_id}
                  to={`/customers/${p.customer_id}/overview`}
                  className={`custom-list d-flex align-items-center px-5 py-4 ${
                    activeClient === p.customer_id ? "active" : ""
                  } ${p.active ? "active-user" : "deactivated-user"}`}
                >
                  {/*begin::Symbol*/}
                  <div className="symbol symbol-40px me-5">
                    <span className="symbol-label">
                      <img
                        src={toAbsoluteUrl(p.image)}
                        alt={p.title}
                        className=" align-self-center"
                      />
                    </span>
                  </div>
                  {/*end::Symbol*/}

                  {/*begin::Description*/}
                  <div className="d-flex flex-column flex-grow-1">
                    {/*begin::Title*/}
                    <h5 className="custom-list-title fw-bold text-gray-800 mb-1">
                      {p.name}
                    </h5>
                    {/*end::Title*/}

                    {/*begin::Link*/}
                    <span className="text-gray-400 fw-bold">{p.item}</span>
                    {/*end::Link*/}
                  </div>
                  {/*begin::Description*/}
                </Link>
              ))}
          </div>
          {/*end::Items*/}
        </div>
        {/*end::Projects*/}
      </div>
    </>
  );
};

export { ClientsTab };
