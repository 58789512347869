/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import { KTIcon } from "../../../helpers";

type Props = {
  handleClose: () => void;
  task: any;
  customer: any;
};

const ViewNotification = ({ handleClose, task, customer }: Props) => {
  return (
    <div
      className="modal fade show block"
      id="kt_modal_create_task"
      aria-hidden="true"
    >
      <div className="modal-dialog mw-650px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              onClick={handleClose}
              role="button"
            >
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </div>

          <div className="modal-body mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center mb-13">
              <h1 className="mb-3">{task?.title}</h1>

              <div className="text-muted fw-bold fs-5">
                {customer?.name} requested {task?.task_title} on{" "}
                <Moment format="MM/DD/YYYY">{task?.created_at}</Moment>
              </div>
            </div>
            <div
              id="kt_modal_new_task_form"
              className="form fv-plugins-bootstrap5 fv-plugins-framework"
            >
              <div className="dynamic-form">
                {task &&
                  task.fields &&
                  JSON.parse(task.fields).length > 0 &&
                  JSON.parse(task.fields).map((field, i) => (
                    <>
                      <div
                        key={`key-${i}`}
                        className="option-box relative row g-9 mb-0 mt-0"
                      >
                        <div className="field-box fv-row fv-plugins-icon-container">
                          {/* <!-- begin::Label --> */}
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">{field.label}</span>
                            <span
                              className="ms-1"
                              data-bs-toggle="tooltip"
                              aria-label="Specify a task name for future usage and reference"
                              data-bs-original-title="Specify a task name for future usage and reference"
                              data-kt-initialized="1"
                            ></span>{" "}
                          </label>
                          {/* <!-- end::Label --> */}

                          {field.type === "Dropdown" ? (
                            <div>
                              {field.options.split(",").map((item) => {
                                if (field.value && field.value[item]) {
                                  return <p>{item}</p>;
                                }
                                return false;
                              })}
                            </div>
                          ) : (
                            <div>
                              <p>{field.value}</p>
                            </div>
                          )}
                          <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              {/* <!-- end::Input group --> */}

              {/* <!-- end::Input group --> */}

              {/* <!-- begin::Actions --> */}
              <div className="text-center mt-8">
                <button
                  type="reset"
                  onClick={handleClose}
                  id="kt_modal_new_task_cancel"
                  className="btn btn-primary me-3"
                >
                  Close
                </button>
              </div>
              {/* <!-- end::Actions --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ViewNotification };
