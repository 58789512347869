/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import { post } from "../../../../app/modules/auth/core/helpers";
import { ViewNotification } from "../../../partials";

type Props = {
  className: string;
  customerID?: string;
  Url?: string;
  title?: string;
  data?: any;
  titleSingular?: string;
  modalID?: string;
  customer?: any;
  UpdateUrl?: string;
};

const ChargesTable: React.FC<Props> = ({
  className = "",
  customerID = "",
  Url = "",
  title = "",
  data = {},
  titleSingular = "",
  modalID = "",
  customer = {},
  UpdateUrl = "",
}) => {
  const { id } = useParams();
  const [tasks, setTasks] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [activeTask, setActiveTask] = useState<any>(false);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    async function fetch() {
      if (Url && data) {
        const taskData = await post(Url, data);
        setTasks(taskData.data);
      }
    }
    fetch();
  }, [id, Url]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const setActiveTaskOnClick = (task) => {
    console.log(task);
    setShowModal(true);
    setActiveTask(task);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">{title}</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bold text-muted bg-light">
                  <th className="ps-4 min-w-125px rounded-start">Customer</th>
                  <th className="min-w-125px">Request</th>
                  <th className="min-w-125px">Created Date</th>
                  <th className="min-w-125px">Status</th>
                  <th className="min-w-125px  rounded-end">Amount</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {tasks &&
                  tasks.length > 0 &&
                  tasks.map((task) => (
                    <tr>
                      <td
                        role="button"
                        onClick={() => setActiveTaskOnClick(task)}
                      >
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50px me-5">
                            <img
                              src={task?.customer?.image}
                              className=""
                              alt=""
                            />
                          </div>
                          <div className="d-flex justify-content-start flex-column">
                            <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                              {task?.customer?.name}
                            </span>
                            <span className="text-muted fw-semibold text-muted d-block fs-7">
                              {task?.customer?.item}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td
                        role="button"
                        onClick={() => setActiveTaskOnClick(task)}
                      >
                        <a className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                          {task.title}
                        </a>
                      </td>
                      <td
                        role="button"
                        onClick={() => setActiveTaskOnClick(task)}
                      >
                        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                          <Moment format="MM/DD/YYYY">{task.created_at}</Moment>
                        </span>
                      </td>

                      <td
                        role="button"
                        onClick={() => setActiveTaskOnClick(task)}
                      >
                        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                          {`${task?.status
                            ?.charAt(0)
                            .toUpperCase()}${task?.status?.slice(1)}`}
                        </span>
                      </td>
                      <td className="">
                        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                          ${task?.amount}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      {showModal && activeTask && (
        <>
          <ViewNotification
            handleClose={hideModal}
            task={activeTask}
            customer={customer}
          />
          <div className="modal-backdrop fade show block"></div>
        </>
      )}
    </>
  );
};

export { ChargesTable };
