/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { KTIcon } from "../../../helpers";
import { post } from "../../../../app/modules/auth/core/helpers";
import { useSearchParams } from "react-router-dom";

type Props = {
  handleClose: () => void;
  incomingTask?: any;
};

const CreateTask = ({ handleClose, incomingTask }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [task, setTask] = useState<any>(
    incomingTask
      ? incomingTask
      : {
          task_title: "",
          price: 0,
          custom: [{ label: "", type: "text" }],
        },
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (incomingTask) {
      const custom = JSON.parse(incomingTask.fields);
      setTask({ task_title: incomingTask.title, ...task, custom: custom });
    }
  }, [incomingTask]);
  /* eslint-enable react-hooks/exhaustive-deps */
  const onSubmit = async () => {
    console.log(task);

    if (
      task.task_title &&
      task.custom &&
      task.custom.length > 0 &&
      task.custom[0].label &&
      task.custom[0].label.length > 0
    ) {
      console.log(task);

      setSearchParams({
        refresh: Math.floor(Date.now() / 1000).toString(),
      });

      console.log(searchParams);
      const savedData = await post("api/create-task", task);
      if (savedData.data.success) {
        handleClose();
      }
    } else {
      console.error("error");
    }
  };

  const addCustomField = (e) => {
    e.preventDefault();

    console.log(task);
    setTask({
      ...task,
      custom: [
        ...task.custom,
        {
          label: "",
          type: "text",
        },
      ],
    });
  };

  const removeTask = async () => {
    console.log(incomingTask);
    const savedData = await post("api/delete-task", incomingTask);
    setSearchParams({
      refresh: Math.floor(Date.now() / 1000).toString(),
    });
    if (savedData.data.success) {
      handleClose();
    }
  };

  const removeCustomField = (i, e) => {
    setTask({
      ...task,
      custom: task.custom.filter((field, index) => i !== index),
    });

    e.preventDefault();
  };

  const changeField = (e) => {
    setTask({
      ...task,
      [e.target.name]: e.target.value,
    });
  };

  const changeFieldNested = (index) => (e) => {
    console.log("index: " + index);
    console.log("property value: " + e.target.value);
    let newArr = [...task.custom]; // copying the old datas array
    // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
    console.log(newArr);
    newArr[index][e.target.name] = e.target.value; // replace e.target.value with whatever you want to change it to

    setTask({
      ...task,
      custom: newArr,
    });
  };

  return (
    <div
      className="modal fade show block"
      id="kt_modal_create_task"
      aria-hidden="true"
    >
      <div className="modal-dialog mw-650px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              role="button"
              onClick={handleClose}
            >
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </div>

          <div className="modal-body mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center mb-13">
              <h1 className="mb-3">Create A Task</h1>

              <div className="text-muted fw-bold fs-5">
                These are the options your customers will have when they access
                the page via URL or QR Code
              </div>
            </div>

            <div
              onSubmit={onSubmit}
              id="kt_modal_new_task_form"
              className="form fv-plugins-bootstrap5 fv-plugins-framework"
            >
              {/* <!-- begin::Input group --> */}
              <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                {/* <!-- begin::Label --> */}
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                  <span className="required">Task Title</span>
                  <span
                    className="ms-1"
                    data-bs-toggle="tooltip"
                    aria-label="Specify a task name for future usage and reference"
                    data-bs-original-title="Specify a task name for future usage and reference"
                    data-kt-initialized="1"
                  ></span>{" "}
                </label>
                {/* <!-- end::Label --> */}

                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder="Enter Task Title"
                  name="task_title"
                  value={task.task_title}
                  onChange={(e) => changeField(e)}
                />
                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                {/* <!-- begin::Label --> */}
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                  <span className="">Task Price</span>
                  <span
                    className="ms-1"
                    data-bs-toggle="tooltip"
                    aria-label="Specify a task name for future usage and reference"
                    data-bs-original-title="Specify a task name for future usage and reference"
                    data-kt-initialized="1"
                  ></span>{" "}
                </label>
                {/* <!-- end::Label --> */}

                <input
                  type="number"
                  className="form-control form-control-solid"
                  placeholder="Enter Task Price"
                  name="price"
                  value={task.price}
                  onChange={(e) => changeField(e)}
                />
                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
              </div>
              {/* <!-- end::Input group --> */}

              <hr />
              <div className="dynamic-form">
                <h1 className="text-dark fw-bolder fs-2 mt-0 mb-4">
                  Custom Fields
                </h1>
                {task &&
                  task.custom &&
                  task.custom.length > 0 &&
                  task.custom.map((field, i) => (
                    <>
                      <div
                        key={`key-${i}`}
                        className="option-box relative row g-9 mb-8 mt-0"
                      >
                        <div className="field-box col-md-6 fv-row fv-plugins-icon-container">
                          {/* <!-- begin::Label --> */}
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Field Label</span>
                            <span
                              className="ms-1"
                              data-bs-toggle="tooltip"
                              aria-label="Specify a task name for future usage and reference"
                              data-bs-original-title="Specify a task name for future usage and reference"
                              data-kt-initialized="1"
                            ></span>{" "}
                          </label>
                          {/* <!-- end::Label --> */}

                          <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Enter Task Title"
                            name={`label`}
                            value={task.custom[i].label}
                            onChange={changeFieldNested(i)}
                          />
                          <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                        </div>
                        <div className="field-box col-md-6 fv-row fv-plugins-icon-container">
                          {/* <!-- begin::Label --> */}
                          <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                            <span className="required">Field Type</span>
                            <span
                              className="ms-1"
                              data-bs-toggle="tooltip"
                              aria-label="Specify a task name for future usage and reference"
                              data-bs-original-title="Specify a task name for future usage and reference"
                              data-kt-initialized="1"
                            ></span>{" "}
                          </label>
                          {/* <!-- end::Label --> */}

                          <select
                            className="form-select form-select-solid select2-hidden-accessible"
                            placeholder="Enter Task Field Type"
                            name={`type`}
                            value={task.custom[i].type}
                            onChange={changeFieldNested(i)}
                          >
                            <option>Text</option>
                            <option>Date</option>
                            <option>Dropdown</option>
                            <option>Textarea</option>
                          </select>

                          <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                        </div>
                        <div className="add-button-box">
                          <>
                            <button
                              className="btn btn-danger"
                              onClick={(e) => removeCustomField(i, e)}
                            >
                              -
                            </button>
                          </>
                        </div>
                      </div>
                      <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                        Separate options by commas
                      </label>
                      {task.custom[i].type === "Dropdown" && (
                        <textarea
                          name={`options`}
                          value={task.custom[i].options}
                          onChange={changeFieldNested(i)}
                        ></textarea>
                      )}

                      <hr />
                    </>
                  ))}
                <button
                  className="btn btn-primary fw "
                  onClick={(e) => addCustomField(e)}
                >
                  Add Field
                </button>
                <hr />
              </div>
              {/* <!-- end::Input group --> */}

              {/* <!-- end::Input group --> */}

              {/* <!-- begin::Actions --> */}
              <div className="text-center">
                <button
                  type="reset"
                  id="kt_modal_new_task_cancel"
                  className="btn btn-light me-3"
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  id="kt_modal_new_task_submit"
                  className="btn btn-primary"
                  onClick={onSubmit}
                >
                  <span className="indicator-label">Submit</span>
                  <span className="indicator-progress">
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
              {/* <!-- end::Actions --> */}
            </div>

            {incomingTask && (
              <>
                <hr />

                <div className="delete-area text-center">
                  <button
                    type="submit"
                    id="kt_modal_new_task_submit"
                    className="btn btn-danger"
                    onClick={removeTask}
                  >
                    <span className="indicator-label">Delete Task</span>
                    <span className="indicator-progress">
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { CreateTask };
