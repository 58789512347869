import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { get } from "../../../../../app/modules/auth/core/helpers";
import { CreateStaff } from "../../../../partials";

const StaffTab = (props) => {
  const { modalChange } = props;
  const history = useLocation();
  const [incomingStaff, setIncomingStaff] = useState<any>(null);
  const [staff, setStaff] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  useEffect(() => {
    async function fetch() {
      const staffData = await get("api/get-staff");
      console.log(staffData);
      setStaff(staffData.data);
    }
    fetch();
  }, [showModal, modalChange, history]);

  const hideModal = () => {
    setShowModal(false);
  };

  const show = (staff) => {
    setIncomingStaff(staff);
    setShowModal(true);
  };

  return (
    <>
      <div className="mx-5">
        {/*begin::Header*/}
        <h3 className="fw-bolder text-dark mb-10 mx-0">Staff Overview</h3>
        {/*end::Header*/}

        {/*begin::Body*/}
        <div className="mb-12">
          {staff &&
            staff.length > 0 &&
            staff.map((a) => (
              <div className="d-flex align-items-center mb-7" key={a.name}>
                {/*begin::Avatar*/}
                <div className="symbol symbol-50px me-5">JH</div>
                {/*end::Avatar*/}

                {/*begin::Text*/}
                <div className="flex-grow-1">
                  <div
                    role="button"
                    onClick={() => show(a)}
                    className="text-dark fw-bolder text-hover-primary fs-6"
                  >
                    {a.name}
                  </div>
                  <span className="text-muted d-block fw-bold">{a.email}</span>
                </div>
              </div>
            ))}
        </div>

        {/*end::Body*/}
      </div>
      {showModal && (
        <>
          <CreateStaff handleClose={hideModal} staff={incomingStaff} />
          <div className="modal-backdrop fade show block"></div>
        </>
      )}
    </>
  );
};

export { StaffTab };
