import axios from "axios";
import { AuthModel } from "../../../app/modules/auth/core/_models";

const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v";
const API_URL = process.env.REACT_APP_API_URL;

export const CLIENTS_URL = `${API_URL}/api/get-clients`;
export const CUSTOMER_URL = `${API_URL}/api/get-customer`;

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

export async function getClients() {
  const token = await getAuth();
  console.log(token);
  const data = await axios.get(CLIENTS_URL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data.data.client.customers;
}

export async function getCustomer(id) {
  const token = await getAuth();
  const data = await axios.post(CUSTOMER_URL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    id,
  });
  return data.data;
}
