/* eslint-disable react/jsx-no-target-blank */
import { FC, useState } from "react";
import { KTIcon } from "../../../../helpers";
import { StaffTab } from "./StaffTab";
import { MenuTab } from "./MenuTab";
import { NotificationsTab } from "./NotificationsTab";
import { ClientsTab } from "./ClientsTab";
import { TasksTab } from "./TasksTab";
import { CreateCustomer, CreateTask, CreateStaff } from "../../../../partials";

type Props = {
  link: string;
  handleClose?: any;
  url?: string;
  modalChange?: boolean;
};

const SelectedTab: FC<Props> = ({ link, modalChange }) => {
  switch (link) {
    case "projects":
      return <ClientsTab modalChange={modalChange} />;
    case "menu":
      return <MenuTab />;
    case "tasks":
      return <TasksTab modalChange={modalChange} />;
    case "notifications":
      return <NotificationsTab modalChange={modalChange} />;
    case "authors":
      return <StaffTab modalChange={modalChange} />;
    default:
      return <ClientsTab modalChange={modalChange} />;
  }
};

const SelectedModal: FC<Props> = ({ link, handleClose }) => {
  switch (link) {
    case "projects":
      return <CreateCustomer handleClose={handleClose} />;
    case "tasks":
      return <CreateTask handleClose={handleClose} />;
    case "authors":
      return <CreateStaff handleClose={handleClose} />;
    default:
      return <div />;
  }
};

const TabsBase: FC<Props> = ({ link, handleClose }) => {
  const [showModal, setShowModal] = useState<any>(false);

  const hideModal = () => {
    setShowModal(false);
  };

  const show = () => {
    setShowModal(true);
  };

  const showSelectedModal = (link) => {
    switch (link) {
      case "projects":
        return true;
      case "tasks":
        return true;
      case "authors":
        return true;

      default:
        return false;
    }
  };

  const createTitle = (link) => {
    switch (link) {
      case "projects":
        return "Create New Customer";
      case "tasks":
        return "Create New Task";
      case "authors":
        return "Create New Staff";
      default:
        return false;
    }
  };

  return (
    <>
      <div className="d-flex h-100 flex-column">
        {/* begin::Wrapper */}
        <div
          className="flex-column-fluid hover-scroll-y"
          data-kt-scroll="true"
          data-kt-scroll-activate="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-wrappers="#kt_aside_wordspace"
          data-kt-scroll-dependencies="#kt_aside_secondary_footer"
          data-kt-scroll-offset="0px"
        >
          {/* begin::Tab content */}
          <div className="tab-content">
            <div
              className="tab-pane fade active show"
              id={`kt_aside_nav_tab_${link}`}
              role="tabpanel"
            >
              <SelectedTab link={link} />
            </div>
          </div>
          {/* end::Tab content */}
        </div>
        {/* end::Wrapper */}
        {/* begin::Footer */}
        {showSelectedModal(link) && (
          <div
            className="flex-column-auto pt-10 px-5 "
            id="kt_aside_secondary_footer"
          >
            <div
              role="button"
              className="btn btn-primary  btn-flex btn-active-color-primary flex-center w-100"
              onClick={show}
            >
              <span className="btn-label">{createTitle(link)}</span>
              <KTIcon iconName="user" className="btn-icon fs-4 ms-2" />
            </div>
          </div>
        )}
        {/* end::Footer */}
      </div>
      {showModal && (
        <>
          <SelectedModal
            handleClose={hideModal}
            link={link}
            modalChange={showModal}
          />
          <div className="modal-backdrop fade show block"></div>
        </>
      )}
    </>
  );
};

export { TabsBase };
