/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";

const Footer: FC = () => {
  return (
    <div className={"footer py-4 d-flex flex-lg-column"} id="kt_footer">
      {/*begin::Container*/}

      {/*end::Container*/}
    </div>
  );
};

export { Footer };
