/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable  react-hooks/exhaustive-deps */
import clsx from "clsx";
import { Dispatch, FC, SetStateAction, useState, useEffect } from "react";
import Pusher from "pusher-js";
import { KTIcon } from "../../../helpers";

const tabs: ReadonlyArray<{ link: string; icon: string; tooltip: string }> = [
  {
    link: "projects",
    icon: "user",
    tooltip: "Clients",
  },
  {
    link: "notifications",
    icon: "notification",
    tooltip: "Notifications",
  },
  {
    link: "tasks",
    icon: "shield-tick",
    tooltip: "Tasks",
  },
  {
    link: "authors",
    icon: "security-user",
    tooltip: "Staff",
  },
];

type Props = {
  link: string;
  setLink: Dispatch<SetStateAction<string>>;
};

const AsideTabs: FC<Props> = ({ link, setLink }) => {
  const [hasNewNotifcation, setHasNewNotifcation] = useState<any | null>(false);
  // Enable pusher logging - don't include this in production
  Pusher.logToConsole = true;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  var pusher = new Pusher("cca9e5b2023da1938dc9", {
    cluster: "us2",
  });

  var channel = pusher.subscribe("my-channel");
  channel.bind("my-event", function (data) {
    setHasNewNotifcation(true);
  });

  useEffect(() => {
    var channel = pusher.subscribe("my-channel");
    channel.bind("my-event", function (data) {
      setHasNewNotifcation(true);
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  }, [link, pusher]);

  const click = (link) => {
    setLink(link);
    if (link === "notifications" && hasNewNotifcation) {
      setHasNewNotifcation(false);
    }
  };

  return (
    <div
      className="hover-scroll-y mb-10"
      data-kt-scroll="true"
      data-kt-scroll-activate="{default: false, lg: true}"
      data-kt-scroll-height="auto"
      data-kt-scroll-wrappers="#kt_aside_nav"
      data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
      data-kt-scroll-offset="0px"
    >
      {/* begin::Nav */}
      <ul className="nav flex-column" id="kt_aside_nav_tabs">
        {/* begin::Nav item */}
        {tabs.map((t) => (
          <li
            key={t.link}
            id={`tab-${t.link}`}
            className={
              t.link === "notifications" && hasNewNotifcation ? "active" : ""
            }
          >
            {/* begin::Nav link */}
            <a
              onClick={() => click(t.link)}
              className={clsx(
                "nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light",
                { active: t.link === link },
              )}
            >
              <KTIcon iconName={t.icon} className="fs-2x" />
            </a>
            {/* end::Nav link */}
          </li>
        ))}
        {/* end::Nav link */}
      </ul>
      {/* end::Tabs */}
    </div>
  );
};

export { AsideTabs };
