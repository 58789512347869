/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useParams, useSearchParams } from "react-router-dom";
import { post, getClass } from "../../../../app/modules/auth/core/helpers";
import { ViewNotification } from "../../../partials";

type Props = {
  className: string;
  customerID?: string;
  Url?: string;
  title?: string;
  data?: any;
  titleSingular?: string;
  modalID?: string;
  customer?: any;
  UpdateUrl?: string;
};

const TablesWidget11: React.FC<Props> = ({
  className = "",
  customerID = "",
  Url = "",
  title = "",
  data = {},
  titleSingular = "",
  modalID = "",
  customer = {},
  UpdateUrl = "",
}) => {
  const { id } = useParams();
  const [tasks, setTasks] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [activeTask, setActiveTask] = useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    async function fetch() {
      if (Url && data) {
        const taskData = await post(Url, data);
        console.log(taskData);
        setTasks(taskData.data);
      }
    }
    fetch();
  }, [id, Url]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const editStatus = async (row, e) => {
    console.log(e.target.value);
    const newData = row;
    newData.status = e.target.value;
    await post(UpdateUrl, { data: newData });
    const taskData = await post(Url, data);
    setTasks(taskData.data);
    setSearchParams({ refresh: Math.floor(Date.now() / 1000).toString() });
  };

  const setActiveTaskOnClick = (task) => {
    console.log(task);
    setShowModal(true);
    setActiveTask(task);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">{title}</span>
          </h3>
          {/* <div className="card-toolbar">
            <a href="#" className="btn btn-sm btn-light-primary">
              <KTIcon iconName="plus" className="fs-2" />
              New {titleSingular}
            </a>
          </div> */}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bold text-muted bg-light">
                  <th className="ps-4 min-w-125px rounded-start">Customer</th>
                  <th className="min-w-125px">Request</th>
                  <th className="min-w-125px">Created Date</th>
                  <th className="min-w-125px">Status</th>
                  <th className="min-w-125px  rounded-end">Edit Status</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {tasks &&
                  tasks.length > 0 &&
                  tasks.map((task) => (
                    <tr>
                      <td
                        role="button"
                        onClick={() => setActiveTaskOnClick(task)}
                      >
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50px me-5">
                            <img
                              src={task?.customer?.image}
                              className=""
                              alt=""
                            />
                          </div>
                          <div className="d-flex justify-content-start flex-column">
                            <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                              {task?.customer?.name}
                            </span>
                            <span className="text-muted fw-semibold text-muted d-block fs-7">
                              {task?.customer?.item}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td
                        role="button"
                        onClick={() => setActiveTaskOnClick(task)}
                      >
                        <a className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                          {task.title}
                        </a>
                      </td>
                      <td
                        role="button"
                        onClick={() => setActiveTaskOnClick(task)}
                      >
                        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                          <Moment format="MM/DD/YYYY">{task.created_at}</Moment>
                        </span>
                      </td>

                      <td
                        role="button"
                        onClick={() => setActiveTaskOnClick(task)}
                      >
                        <span
                          className={`badge ${getClass(
                            task.status,
                          )} fs-7 fw-semibold`}
                        >
                          {task.status}
                        </span>
                      </td>
                      <td className="text-end">
                        <select
                          defaultValue={task.status}
                          onChange={(e) => editStatus(task, e)}
                          className="form-select form-select-solid form-select-sm select2-hidden-accessible"
                        >
                          <option value="Rejected">Rejected</option>
                          <option value="Pending">Pending</option>
                          <option value="Paid">Paid</option>
                          <option value="Approved">Approved</option>
                          <option value="Ready">Ready</option>
                          <option value="Completed">Completed</option>
                        </select>
                      </td>
                    </tr>
                  ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      {showModal && activeTask && (
        <>
          <ViewNotification
            handleClose={hideModal}
            task={activeTask}
            customer={customer}
          />
          <div className="modal-backdrop fade show block"></div>
        </>
      )}
    </>
  );
};

export { TablesWidget11 };
