import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { postImage, post } from "../../../../auth/core/helpers";
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from "../SettingsModel";
import * as Yup from "yup";
import { useFormik } from "formik";

type Props = {
  customer: any;
  handleClose: any;
  isModal?: boolean;
};

const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required("Full name is required"),
  email: Yup.string().required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  item: Yup.string().required("Item is required"),
});

const ProfileDetails = ({ handleClose, customer, isModal = false }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [image, setImage] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);

  const storeImage = async (e) => {
    const imageData = await postImage(e?.target?.files[0]);
    setImage(imageData);
  };
  const [loading, setLoading] = useState(false);

  const formik = useFormik<IProfileDetails>({
    initialValues: customer ? customer : initialValues,
    enableReinitialize: true,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      console.log("FEWFEW");
      setLoading(true);
      try {
        setLoading(true);
        const res = await post(
          customer ? "api/update-customer" : "api/save-customer",
          {
            values,
            image,
          },
        );
        if (res.data.success) {
          setStatus(null);
          setSearchParams({
            refresh: Math.floor(Date.now() / 1000).toString(),
          });
          setTimeout(() => {
            setLoading(false);
            if (handleClose) {
              handleClose();
            }
          }, 1000);
        } else {
          setStatus(res.data.text);
          setLoading(false);
        }
      } catch (error) {
        setStatus("An Error Occurred");
        setLoading(false);
      }
    },
  });
  return (
    <div className={!isModal ? "card mb-5 mb-xl-10" : ""}>
      {!isModal && (
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">Profile Details</h3>
          </div>
        </div>
      )}

      <div id="kt_account_profile_details" className="collapse show">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="card-body border-top p-9">
            <div className="row  mb-6">
              <label className="col-lg-4 col-form-label  fw-bold fs-6">
                Avatar
              </label>
              <div className="col-lg-8">
                {/* <!--begin::Image input--> */}
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                  style={{
                    backgroundImage: `url(
                      "/media/svg/avatars/blank.svg"
                    )`,
                  }}
                >
                  {/* <!--begin::Preview existing avatar--> */}
                  <div
                    className="image-input-wrapper w-125px h-125px"
                    style={
                      image && image.data
                        ? {
                            backgroundImage: `url(
                        https://sms.jeanhules.com/storage/${image.data}
                      )`,
                          }
                        : {
                            backgroundImage: `url(
                        ${
                          customer && customer.image
                            ? customer.image
                            : "/placeholder.jpg"
                        }
                      )`,
                          }
                    }
                  ></div>
                  {/* <!--end::Preview existing avatar--> */}

                  {/* <!--begin::Label--> */}
                  <label
                    className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="change"
                    data-bs-toggle="tooltip"
                    aria-label="Change avatar"
                    data-bs-original-title="Change avatar"
                    data-kt-initialized="1"
                  >
                    <i className="ki-duotone ki-pencil fs-7">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    {/* <!--begin::Inputs--> */}
                    <input
                      type="file"
                      name="avatar"
                      onChange={storeImage}
                      accept=".png, .jpg, .jpeg"
                    />
                    <input type="hidden" name="avatar_remove" />
                    {/* <!--end::Inputs--> */}
                  </label>
                </div>
                {/* <!--end::Image input--> */}

                {/* <!--begin::Hint--> */}
                <div className="form-text">
                  Allowed file types: png, jpg, jpeg.
                </div>
                {/* <!--end::Hint--> */}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Full Name
              </label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Full Name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Contact Phone</span>
              </label>

              <div className="col-lg-8 ">
                <div className="">
                  <input
                    type="tel"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Phone number"
                    {...formik.getFieldProps("phone")}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.phone}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Email
              </label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Email "
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">
                Item You Manage
              </label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Enter Car Info Here"
                      {...formik.getFieldProps("item")}
                    />
                    {formik.touched.item && formik.errors.item && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.item}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {status && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{status}</div>
            </div>
          )}

          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {!loading && "Save Changes!"}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { ProfileDetails };
