import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import { KTIcon } from "../../../../helpers";
import Pusher from "pusher-js";
import {
  get,
  getClass,
  post,
} from "../../../../../app/modules/auth/core/helpers";

const NotificationsTab = (props) => {
  const { modalChange } = props;
  const [notifications, setNotifications] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    async function fetch() {
      const notificationData = await get("api/get-notifications");
      console.log(notificationData);
      setNotifications(notificationData.data);
    }
    fetch();
  }, [modalChange, searchParams]);

  var pusher = new Pusher("cca9e5b2023da1938dc9", {
    cluster: "us2",
  });

  var channel = pusher.subscribe("my-channel");
  channel.bind("my-event", async function (data) {
    const notificationData = await get("api/get-notifications");
    console.log(notificationData);
    setNotifications(notificationData.data);
  });

  const read = async (notification) => {
    if (notification.type === "comment") {
      await post("api/read-notifications", notification);
      const notificationData = await get("api/get-notifications");
      console.log(notificationData);
      setNotifications(notificationData.data);
    }
  };

  return (
    <div className="mx-5">
      {/*begin::Header*/}
      <h3 className="fw-bolder text-dark mb-10 mx-0">Notifications</h3>
      {/*end::Header*/}

      {/*begin::Body*/}
      <div className="mb-12">
        {notifications &&
          notifications.length > 0 &&
          notifications.map((n) => (
            <div
              key={n.text}
              className={`${getClass(
                n.status,
              )} d-flex align-items-center bg-light-${
                n.color
              } rounded p-5 mb-7`}
            >
              <KTIcon
                iconName={n.icon}
                className={`-${n.color} me-5 fs-1 text-${n.color}`}
              />

              {/*begin::Title*/}
              <div className="flex-grow-1 me-2" onClick={() => read(n)}>
                <Link
                  to={
                    n.type === "comment"
                      ? `/customers/${n.customer_id}/car/${n.car_id}/overview?note_id=${n.note_id}`
                      : `/customers/${n.customer_id}/overview`
                  }
                  className="fw-bolder text-gray-800 text-hover-primary fs-6"
                >
                  {n.type === "comment"
                    ? `${n.title}`
                    : `${n.customer?.name} has requested ${n.title}`}
                </Link>

                <span className="text-muted fw-bold d-block">
                  <Moment format="MM/DD/YYYY">{n.created_at}</Moment>
                </span>

                {/*end::Title*/}

                {/*begin::Lable*/}
                <span className={`fw-bolder text-${n.color} py-1`}>
                  {n.status}
                </span>
                {/*end::Lable*/}
              </div>
            </div>
          ))}
      </div>
      {/*end::Body*/}
    </div>
  );
};

export { NotificationsTab };
